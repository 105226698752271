(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name neo.authentication.registration.controller:RegistrationCtrl
   *
   * @description
   *
   */
  angular
  .module('neo.authentication.registration')
  .controller('RegistrationCtrl', RegistrationCtrl);

  function RegistrationCtrl($filter, $state, $stateParams, $mdToast, Registration) {
    var vm = this;
    vm.ctrlName = 'RegistrationCtrl';

    vm.cardTitle = 'REGISTER_PASSWORD';
    vm.pwd = undefined;
    vm.pwd2 = undefined;

    vm.sendPassword = function () {
      vm.newUser = {password: vm.pwd, token: $stateParams.token};
      Registration.save(vm.newUser,
        function () {
          $mdToast.show(
            $mdToast.simple()
            .textContent($filter('translate')('SUCCESSFUL_USER'))
            .position('bottom left')
            .hideDelay(3000)
          );
          $state.go('authentication.login');
        },
        function () {
          $mdToast.show(
            $mdToast.simple()
            .textContent($filter('translate')('TOAST_ERROR'))
            .position('bottom left')
            .hideDelay(3000)
          );
        });
    };
  }
}());
